import React, {useState} from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import axios from 'axios'
import Obfuscate from 'react-obfuscate';
import Input from "../components/Input"
import Layout from '../components/layout'
import SEO from '../components/SEO'

const ContactPage = () => {
  const data = useStaticQuery(
    graphql`
    query {
      wpPage(slug: {eq: "contact"}) {
        content
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
                gatsbyImageData(
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
    `
  )
  const content = data.wpPage.content
  const imageData = data.wpPage.featuredImage.node.localFile.childImageSharp.fluid

  const [formStatus, setFormStatus] = useState('Submit Enquiry')

  const handleSubmitForm = (event) => {
    event.preventDefault();
    setFormStatus('Sending...')
    
    const data = new FormData(event.target);
    let formData = {
      name: data.get('name'),
      last_name: data.get('last_name'),
      email: data.get('email'),
      message: data.get('enquiry')
    }

    axios.post('https://brondesburyinteriors.co.uk/mail/sendMail.php', formData)
      .then((res) => {
        setFormStatus('Sent')
        setTimeout(() => { setFormStatus('Submit Enquiry') }, 5000)
      })
      .catch((err) => {
        setFormStatus('Not Sent? Try Again.')
      })
  }


  return (
    <>
      <SEO title='Contact Us' />
      <Layout {...{ content, imageData }}>
`       <div className='project-container contact-container'>
`         <h1>Contact Us</h1>
          <div className='contact-details'>
            <form method='post' onSubmit={handleSubmitForm}>
              <Input name='name' placeholder='Name' reset={formStatus==='Sent' && true} required />
              <Input name='email' type='email' placeholder='Email' reset={formStatus==='Sent' && true} required />
              <Input name='enquiry' type='textarea' placeholder='Enquiry' reset={formStatus==='Sent' && true} required />
              <Input name='last_name' placeholder='Last Name' honeypot />
              <button type='submit'>{formStatus}</button>
            </form>
            <address>
              <h3>Andrew Stephens</h3>
              <Obfuscate email='info@brondesburyinteriors.co.uk' subject='Website Enquiry' />
              <Obfuscate tel='0208 459 5577' />
              <Obfuscate tel='07846 960 761' />
            </address>            
          </div>
        </div>
      </Layout>
    </>
  )
}

export default ContactPage
